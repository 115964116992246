import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import {DataService} from '../data.service';
import {Feedback} from '../feedback';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Location} from '@angular/common';
import {faSmile} from '@fortawesome/free-regular-svg-icons';
import {faFrown} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})

export class SurveyComponent implements OnInit {

  public answers: any[] = [];

  surveyQuestion3Message: string;

  dateToday = new Date();
  serviceOrderNumber = '';
  CSR_ID = '';
  CSRName = '';
  questions: any;
  questionsLoop: any;

  BRANCH_ID = 0;
  BranchName = '';
  branchState = '';
  @ViewChild('successContent') successModal;

  submitted = false;
  surveyForm: FormGroup;

  faSmile = faSmile;
  faFrown = faFrown;

  reasons: any[] = [];

  checkedCheckboxes: any[] = [];

  showAlert = false;

  COMPANY_ID = 56;

  hideCSR = false;

  constructor(private formBuilder: FormBuilder, private dataService: DataService, private router: Router, private ngxLoader: NgxUiLoaderService, private location: Location, private el: ElementRef) {
  }

  async ngOnInit() {
    this.surveyForm = this.formBuilder.group({
      orderNumber: [
        '',
      ],
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
    });
    this.ngxLoader.start();


    const url_string = window.location.href;
    const url = new URL(url_string);
    this.CSR_ID = url.searchParams.get('c');
    this.BRANCH_ID = Number(url.searchParams.get('b'));
    this.COMPANY_ID = Number(url.searchParams.get('i')) === 0 ? 56 : Number(url.searchParams.get('i'));

    this.hideCSR = this.COMPANY_ID === 56;


    if (this.CSR_ID == null || this.BRANCH_ID == null) {
      this.ngxLoader.stop();
      this.router.navigate(['/something-wrong', 'EMPTY']);
    }

    this.location.replaceState('');

    await this.getQuestions();

    // alert(this.COMPANY_ID);
    this.dataService.getBranchDetails(this.COMPANY_ID, this.BRANCH_ID).subscribe(
      branchResponse => {
        if (branchResponse && Object.keys(branchResponse).length > 0) {
          this.BranchName = branchResponse.BRANCH_NAME;
          this.branchState = branchResponse.STATE_PROVINCE;
          if (this.COMPANY_ID === 56) {
            this.dataService.getCSRDetails(this.CSR_ID, this.COMPANY_ID, this.BRANCH_ID).subscribe(
              csrResponse => {
                // // console.log('csrResponse', csrResponse);
                if (csrResponse && Object.keys(csrResponse).length > 0) {
                  this.CSRName = csrResponse.fla.FULLNAME;
                  const status = csrResponse.fla.STATUS.toUpperCase();
                  // console.log(status);
                  if (status === 'INACTIVE') {
                    this.router.navigate(['/something-wrong', 'INACTIVE'], {replaceUrl: true});
                  }
                } else {
                  this.router.navigate(['/something-wrong', 'EMPTY'], {replaceUrl: true});
                }
                this.ngxLoader.stop();
              },
              err => {
                // console.log(err);
                this.ngxLoader.stop();
              }
            );
          } else {
            this.ngxLoader.stop();
          }
        } else {
          this.router.navigate(['/something-wrong', 'EMPTY'], {replaceUrl: true});
          this.ngxLoader.stop();
        }
      },
      err => {
        // console.log(err);
        this.ngxLoader.stop();
        this.router.navigate(['/something-wrong', 'ERROR'], {replaceUrl: true});
      }
    );
  }

  // questionFilter(questionsArray, question): any {
  //   if()
  //   questionsArray.remove(question);
  // }


  async getQuestions(): Promise<void> {
    await this.dataService.getQuestions().subscribe(
      response => {
        try {
          this.questions = response;
          // TODO: Remove when not staging anymore
          this.questions = this.questions.filter(q => q.id !== 1);


          this.questionsLoop = response;

          this.questions = this.questions.filter(x => {
            if (x.Companies != null) {
              const qb = JSON.parse(x.Companies);
              return qb.includes(this.COMPANY_ID);
            } else {
              return true;
            }
          });

          this.questionsLoop = this.questionsLoop.filter(x => {
            if (x.Companies != null) {
              const qb = JSON.parse(x.Companies);
              return qb.includes(this.COMPANY_ID);
            } else {
              return true;
            }
          });


          for (const question of this.questionsLoop) {
            if (question.Branches == null) {
              if (question.IsFreeForm === 6) {
                this.answers.push({
                  order: question.Question_Order,
                  answer: '',
                  id: question.id,
                  yesNo: ''
                });
              } else if (question.id !== 1) { // TODO: REMOVE if when not staging anymore
                this.answers.push({
                  order: question.Question_Order,
                  answer: '',
                  id: question.id,
                  yesNo: ''
                });
              }
            } else {
              try {
                const questionBranches = JSON.parse(question.Branches).ids;
                if (questionBranches.includes(this.BRANCH_ID)) {
                  if (question.IsFreeForm === 6) {
                    this.answers.push({
                      order: question.Question_Order,
                      answer: '',
                      id: question.id,
                      yesNo: ''
                    });
                  } else if (question.id !== 1) { // TODO: REMOVE if when not staging anymore
                    this.answers.push({
                      order: question.Question_Order,
                      answer: '',
                      id: question.id,
                      yesNo: ''
                    });
                  }
                } else {
                  this.questions = this.questions.filter(x => {
                    if (x.Branches != null) {
                      const qb = JSON.parse(question.Branches).ids;
                      return qb.includes(this.BRANCH_ID);
                    } else {
                      return true;
                    }
                  });
                }
              } catch (error) {
                // console.log(error);
              }
            }

            // console.log(this.answers);
          }
          this.questions.sort((a, b) => a.Question_Order - b.Question_Order);
          this.answers.sort((a, b) => a.order - b.order);
        } catch (e) {
          // console.log(e);
        }
      },
      err => {
        // console.log(err);
        this.ngxLoader.stop();
      }
    );
  }

  get sf() {
    return this.surveyForm.controls;
  }

  onSubmit(): void {
    // console.log(this.answers);
    for (const answer of this.answers) {
      if (answer.yesNo === 2) {
        continue;
      }
      if (answer.answer === '' || this.reasons.length < 1) {
        this.showAlert = true;
        return;
      }
    }

    const invalidControl = this.el.nativeElement.querySelector('#mobileNumber.ng-invalid');
    this.submitted = true;
    // console.log(this.surveyForm.get('mobileNumber').errors);
    if (invalidControl) {
      // console.log(invalidControl);
      invalidControl.focus();
    }
    if (this.surveyForm.invalid) {
      return;
    }
    this.submitSurvey();
  }

  submitSurvey(): void {
    this.showAlert = false;
    this.ngxLoader.start();
    const questionObject: any = [];
    for (const question of this.questions) {
      const theAnswer = this.answers.find(answer => answer.id === question.id);
      if (question.IsFreeForm === 6 && theAnswer.yesNo !== 1) {
        theAnswer.answer = 2;
      }
      questionObject.push({
        id: question.id,
        answer: theAnswer.answer
      });
    }

    const feedback: Feedback = {
      ServiceOrder: this.surveyForm.get('orderNumber').value,
      Company: this.COMPANY_ID,
      VisitDate: new Date(),
      Branch: this.BRANCH_ID,
      CSRName: this.CSR_ID.toString(),
      Feedback: JSON.stringify(questionObject),
      MobileNumber: '+63' + this.surveyForm.get('mobileNumber').value,
      Created_by: 1,
      Region: this.branchState,
      Reasons: JSON.stringify(this.reasons)
    };

    this.dataService.addFeedback(feedback).subscribe(
      response => {
        // console.log(response);
        this.ngxLoader.stop();
        this.router.navigate(['/success']);
      },
      err => {
        // console.log(err);
        this.ngxLoader.stop();
      }
    );
  }


  checkboxClick(event: any, i: number, score: number, reason: string): void {
    // console.log('checkClick', event.target.checked, i, score, reason);
    const selectedAnswer = this.answers.find(a => a.id === i);
    // console.log('selectedAnswer before', selectedAnswer);
    // console.log('score', score);
    if (event.target.checked === true) { // checked
      const answerParsed = parseInt(selectedAnswer.answer, 10);
      // console.log('answerParsed', answerParsed);
      this.checkedCheckboxes.push({
        id: i, score, reason, checkboxID: event.target.id
      });
      if (!isNaN(answerParsed)) {
        if (score <= 0) {
          if (answerParsed > score) { // sad
            selectedAnswer.answer = score;
          }
        } else {
          if (answerParsed < score) { // happy
            selectedAnswer.answer = score;
          }
        }
      } else {
        selectedAnswer.answer = score;
      }

      this.reasons.push({id: i, reason});
      // // console.log('selectedAnswer check', selectedAnswer);

    } else { // uncheckd
      this.checkedCheckboxes = this.checkedCheckboxes.filter(cc => cc.checkboxID !== event.target.id);
      let highest: any = 10;
      // const selectedAnswer = this.answers.find(a => a.id === i);
      if (this.checkedCheckboxes.length > 0) {
        this.checkedCheckboxes.forEach(cc => {
          if (highest === 10) {
            highest = cc.score;
          } else {
            if (highest <= 0) {
              if (cc.score > highest) { // sad
                highest = cc.score;
              }
            } else {
              if (cc.score < highest) { // happy
                highest = cc.score;
              }
            }
          }
        });
      } else {
        highest = '';
      }

      selectedAnswer.answer = highest;
      this.reasons = this.reasons.filter(m => m.id !== i || m.reason !== (reason));
    }
    console.log('checkedCheckboxes', this.checkedCheckboxes);
    console.log('reasons', this.reasons);
  }

  clearCheckboxes(i: number): void {
    this.checkedCheckboxes = this.checkedCheckboxes.filter(cc => cc.id !== i);
    this.reasons = [];
    console.log(this.checkedCheckboxes);
    console.log(this.reasons);

  }

}
