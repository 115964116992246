import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Feedback } from './feedback';
import { Observable } from 'rxjs';
import {Question} from './question';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  // private feedbackPostUrl = ''; // DEV
  private feedbackPostUrl = 'https://qapps-survey.azurewebsites.net';

  constructor(private httpClient: HttpClient) { }

  public getQuestions(): Observable<Question[]>{
    return this.httpClient.get<Question[]>(`${this.feedbackPostUrl}/api/Survey/GetAllSurveyQuestions`);

  }

  public addFeedback(feedback: Feedback): Observable<Feedback>{
    return this.httpClient.post<Feedback>(`${this.feedbackPostUrl}/api/Survey/AddSamsungSurvey`, feedback);

  }

  public getAllFeedback(): Observable<Feedback[]>{
    return this.httpClient.get<Feedback[]>(`${this.feedbackPostUrl}/api/Survey/GetallSamsungSurvey`);

  }

  public getCSRSurveyByName(CSRName: string, startDate: string, endDate: string): Observable<any>{
    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('CSRName', CSRName);
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    return this.httpClient.get<any>(`${this.feedbackPostUrl}/api/Survey/GetallSamsungSurveyByID`, {params});

  }

  public getCSRSurveyByBranch(Branch: string, startDate: string, endDate: string): Observable<any>{
    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('Branch', Branch);
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    return this.httpClient.get<any>(`${this.feedbackPostUrl}/api/Survey/GetallSamsungSurveyByBranch`, {params});

  }

  public getCSRSurveyByRegion(Region: string, startDate: string, endDate: string): Observable<any>{
    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('region', Region);
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    return this.httpClient.get<any>(`${this.feedbackPostUrl}/api/Survey/GetallSamsungSurveyByRegion`, {params});

  }


  public getBranchDetails(companyId: number, branchId: number): Observable<any>{
    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('company_id', companyId.toString());
    params = params.append('branch_id', branchId.toString());
    return this.httpClient.get<any>('https://qapps-api.timefree.ph/api/QMobile/GetBranchAsync', {params});

  }


  public getCSRDetails(userName: string, companyId: number, branchId: number): Observable<any>{
    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('username', userName.toString());
    params = params.append('company_id', companyId.toString());
    params = params.append('branch_id', branchId.toString());
    return this.httpClient.get<any>('https://qapps-api.timefree.ph/api/QPad/GetFrontlinerDetailsAsync', {params});

  }

  public getAllBranchesByCompanyId(companyId: number): Observable<any>{
    // Initialize Params Object
    let params = new HttpParams();
    // Begin assigning parameters
    params = params.append('company_id', companyId.toString());
    return this.httpClient.get<any>('https://qapps-api.timefree.ph/api/QMobile/GetAllBranchesByCompanyWithUnlistedAsync', {params});
  }

  public getAllFrontlinersByBranch(companyId: number, branchId: number): Observable<any>{
    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('company_id', companyId.toString());
    params = params.append('branch_id', branchId.toString());
    return this.httpClient.get<any>('https://qapps-api.timefree.ph/api/QManager/GetAllFrontlinersByBranch', {params});

  }

  public getUserAccess(username: string): Observable<any>{
    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('username', username);
    return this.httpClient.get<any>('https://qapps-api.timefree.ph/api/Auth/GetUserDetails', {params});

  }















}
