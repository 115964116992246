import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { DataService } from './data.service';
import { Feedback } from './feedback';
declare let gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Samsung Customer Survey';

  // constructor(private router: Router) {
  //   this.router.events.subscribe(event => {
  //     if (event instanceof NavigationEnd) {
  //       gtag('config', 'G-1DE3LPJVZN',
  //         {
  //           page_path: event.urlAfterRedirects
  //         }
  //       );
  //     }
  //   });
  // }






}




