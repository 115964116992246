import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-something-went-wrong',
  templateUrl: './something-went-wrong.component.html',
  styleUrls: ['./something-went-wrong.component.scss']
})
export class SomethingWentWrongComponent implements OnInit {
  errorMessage = 'Something went wrong with the link.';
  backDisabled = false;

  constructor(private titleService: Title, private _location: Location, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      // console.log('error params', params.get('error'));
      const name = params.get('error');
      switch (name) {
        case 'INACTIVE':
          this.titleService.setTitle('Inactive CSR');
          this.errorMessage = 'The QR code you just scanned was for an inactive staff. Please try scanning another QR code.';
          this.backDisabled = true;
          break;
        case 'EMPTY':
          this.titleService.setTitle('Invalid QR');
          this.errorMessage = 'Something went wrong. Please try scanning the QR code again.';
          this.backDisabled = true;
          break;
        default:
          this.titleService.setTitle('Something Went Wrong');
          this.errorMessage = 'Please try scanning the QR code again.';
          break;
      }
    });
  }

  goBack(): void {
    this._location.back();
  }
}
