<div class="mx-4 mt-4">
  <div class="row mb-3">
    <div class="col">
      <div>
        <b>Report Type</b>
      </div>
      <div>
        <label>
          <select [(ngModel)]="tableSelect" (change)="reportTypeChange()" class="form-control">
            <option [value]="'overview'">Overview</option>
<!--            <option [value]="'tally'">CSR Tally</option>-->
            <option [value]="'raw'">Raw</option>
            <option [value]="'branchTally'">Branch Tally</option>
            <option [value]="'regionTally'">Region Tally</option>
          </select>
        </label>
      </div>
    </div>
    <div class=" col ">
      <button type="button" class="btn btn-dark  btn-lg float-right" (click)="downloadCSV()"
              [disabled]="isCsvButtonDisabled">
        Export to Excel
      </button>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-6" *ngIf="tableSelect === 'regionTally'">
      <div>
        <b>Regions</b>
      </div>
      <select [(ngModel)]="regionSelect"  (change)="getRegionTally()" class="form-control">
<!--        <option  ngValue="ALL">ALL</option>-->
        <option *ngFor="let region of regions" [ngValue]="region">
          {{ region }}
        </option>
      </select>
    </div>
    <div class="col-6" *ngIf="tableSelect !== 'regionTally'">
      <div>
        <b>Branch</b>
      </div>
      <select [(ngModel)]="branchSelect"  (change)="selectBranch(branchSelect)" class="form-control">
        <option [hidden]="tableSelect !== 'branchTally' && tableSelect !== 'overview'" ngValue="ALL">ALL</option>
        <option *ngFor="let branch of branches" [ngValue]="branch.id">
          {{ branch.BRANCH_NAME }}
        </option>
      </select>
    </div>
    <div class="col-6">
      <div>
        <b>CSR</b>
      </div>
      <select [(ngModel)]="csrSelect"  (change)="selectCsr(csrSelect)" class="form-control"
              [disabled]="tableSelect === 'branchTally' || tableSelect === 'overview'">
        <option value="ALL">ALL</option>
        <option *ngFor="let csr of csrs" [ngValue]="csr.FRONTLINER_ID">
          {{ csr.FULLNAME }}
        </option>
      </select>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-6">
      <div>
        <b>Start Date</b>
      </div>
      <div>
        <!--    KeyPress  filter    -->
<!--        <input type="date" [ngModel]="startDateFilter | date:'full'" (keypress)="checkKeypress($event)" class="form-control" name=""-->
<!--               (ngModelChange)="updateStartDate($event)">-->
        <input type="date" [ngModel]="startDateFilter | date:'yyyy-MM-dd'" onkeydown="return false" class="form-control" name=""
               (ngModelChange)="updateStartDate($event)">
      </div>
    </div>
    <div class="col-6">
      <div>
        <b>End Date</b>
      </div>
      <div>
        <input type="date" [ngModel]="endDateFilter | date:'yyyy-MM-dd'" onkeydown="return false" class="form-control" name=""
               (ngModelChange)="updateEndDate($event)">
      </div>
    </div>
  </div>

  <div class="table-responsive " *ngIf="tableSelect === 'tally' || tableSelect === 'raw'">
    <table class="table">
      <thead>
      <tr>
        <th scope="col">CSR Name</th>
        <th scope="col">Questions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let csr of csrDisplayData">
        <td>
          {{csr.CSRName}}
        </td>
        <td>
          <div *ngIf="csr.tally.length===0 || csr.raw.length===0; else tallyAvailable">
            No data for this CSR yet.
          </div>

          <ng-template #tallyAvailable>
            <div *ngIf="tableSelect === 'tally'">
              <div class="card my-1" *ngFor="let tally of csr.tally">
                <div class="card-body">
                  <p>
                    {{tally.question}}
                  </p>
                  <div *ngIf="tally.isFreeForm === 5">
                    <div class="row">
                      <div class="col-sm-6 col-md-2 text-center text-danger">
                        <div>
                          Very Dissatisfied
                        </div>
                        <div>
                          {{getRating(-2, tally.answer)}}
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 text-center text-warning">
                        <div>
                          Dissatisfied
                        </div>
                        <div>
                          {{getRating(-1, tally.answer)}}
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 text-center text-dark">
                        <div>
                          Neutral
                        </div>
                        <div>
                          {{getRating(0, tally.answer)}}
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 text-center text-primary">
                        <div>
                          Satisfied
                        </div>
                        <div>
                          {{getRating(1, tally.answer)}}
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 text-center text-success">
                        <div>
                          Very Satisfied
                        </div>
                        <div>
                          {{getRating(2, tally.answer)}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="overflow-y: scroll; max-height: 150px;" *ngIf="tally.isFreeForm === 0">
                    <div *ngFor="let answerString of tally.answer">
                      "{{answerString}}"
                      <hr>
                    </div>
                  </div>
                  <div *ngIf="tally.isFreeForm === 6">
                    <div>
                      Yes Count: {{getRating(2, tally.answer)}}
                    </div>
                    <div>
                      No Count: {{getRating(1, tally.answer)}}
                    </div>
                    <div>
                      Reason for not activating:
                    </div>
                    <div style="overflow-y: scroll; max-height: 150px;">
                      <div *ngFor="let reason of getYesNo(tally.answer)">
                        "{{reason}}"
                        <hr>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                  <div *ngIf="tally.isFreeForm === 2">
                    <div>
                      Yes Count: {{getRating(2, tally.answer)}}
                    </div>
                    <div>
                      No Count: {{getRating(1, tally.answer)}}
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div *ngIf="tableSelect === 'raw'">
              <div>
                <div class="card my-1" *ngFor="let raw of csr.raw">
                  <div class="card-body">
                    <div class="card-title">
                      <div class="row">
                        <div class="col">
                          <b>{{raw.MobileNumber}}</b>
                        </div>
                        <div class="col pull-right" style="font-size: smaller">
                          <div class="float-right">
                            Date: {{raw.Date_Created | date}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngFor="let question of questions; let qIndex = index" class="mb-3">
                      {{question.Question}}
                      <div *ngIf="question.IsFreeForm === 5">
                        <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === 2"
                             class="text-success">
                          Very Satisfied
                        </div>
                        <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === 1"
                             class="text-primary">
                          Satisfied
                        </div>
                        <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === 0"
                             class="text-dark">
                          Neutral
                        </div>
                        <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === -1"
                             class="text-warning">
                          Dissatisfied
                        </div>
                        <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === -2"
                             class="text-danger">
                          Very Dissatisfied
                        </div>
                      </div>
                      <div *ngIf="question.IsFreeForm === 0">
                        <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback).answer != ''">
                          "{{getQuestionFeedbackAnswer(question.id, raw.Feedback).answer}}"
                        </div>
                        <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback).answer == ''">
                          No Answer
                        </div>
                      </div>
                      <div *ngIf="question.IsFreeForm === 6 ">
                        <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback) != ''">
                          <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === 2">
                            Yes
                          </div>
                          <div
                            *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback).answer !== 2 && getQuestionFeedbackAnswer(question.id, raw.Feedback).answer != null">
                            No, because: {{getQuestionFeedbackAnswer(question.id, raw.Feedback).answer}}
                          </div>
                        </div>
                        <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback) == ''">
                          No Answer
                        </div>
                      </div>
                      <div *ngIf="question.IsFreeForm === 2">
                        <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback) != ''">
                          <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === 2">
                            Yes
                          </div>
                          <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback).answer === 1">
                            No
                          </div>
                        </div>
                        <div *ngIf="getQuestionFeedbackAnswer(question.id, raw.Feedback) == ''">
                          No Answer
                        </div>
                      </div>
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="table-responsive " *ngIf="tableSelect === 'branchTally'">
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Branch Name</th>
        <th scope="col">Questions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let branch of branchDisplayData">
        <td>
          {{branch.BranchName}}
        </td>
        <td>
          <div *ngIf="branch.tally.length===0; else tallyAvailable">
            No data for this Branch yet.
          </div>
          <ng-template #tallyAvailable>
            <div>
              <div class="card my-1" *ngFor="let tally of branch.tally">
                <div class="card-body">
                  <p>
                    {{tally.question}}
                  </p>
                  <div *ngIf="tally.isFreeForm === 5">
                    <div class="row">
                      <div class="col-sm-6 col-md-2 text-center text-danger">
                        <div>
                          Very Dissatisfied
                        </div>
                        <div>
                          {{getRating(-2, tally.answer)}}
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 text-center text-warning">
                        <div>
                          Dissatisfied
                        </div>
                        <div>
                          {{getRating(-1, tally.answer)}}
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 text-center text-dark">
                        <div>
                          Neutral
                        </div>
                        <div>
                          {{getRating(0, tally.answer)}}
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 text-center text-primary">
                        <div>
                          Satisfied
                        </div>
                        <div>
                          {{getRating(1, tally.answer)}}
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 text-center text-success">
                        <div>
                          Very Satisfied
                        </div>
                        <div>
                          {{getRating(2, tally.answer)}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="overflow-y: scroll; max-height: 150px;" *ngIf="tally.isFreeForm === 0">
                    <div *ngFor="let answerString of tally.answer">
                      "{{answerString}}"
                      <hr>
                    </div>
                  </div>
                  <div *ngIf="tally.isFreeForm === 6">
                    <div>
                      Yes Count: {{getRating(2, tally.answer)}}
                    </div>
                    <div>
                      No Count: {{getRating(1, tally.answer)}}
                    </div>
                    <div>
                      Reason for not activating:
                    </div>
                    <div style="overflow-y: scroll; max-height: 150px;">
                      <div *ngFor="let reason of getYesNo(tally.answer)">
                        "{{reason}}"
                        <hr>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                  <div *ngIf="tally.isFreeForm === 2">
                    <div>
                      Yes Count: {{getRating(2, tally.answer)}}
                    </div>
                    <div>
                      No Count: {{getRating(1, tally.answer)}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </ng-template>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="table-responsive " *ngIf="tableSelect === 'regionTally'">
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Region Name</th>
        <th scope="col">Questions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let branch of regionDisplayData">
        <td>
          {{branch.RegionName}}
        </td>
        <td>
          <div *ngIf="branch.tally.length===0; else tallyAvailable">
            No data for this Branch yet.
          </div>
          <ng-template #tallyAvailable>
            <div>
              <div class="card my-1" *ngFor="let tally of branch.tally">
                <div class="card-body">
                  <p>
                    {{tally.question}}
                  </p>
                  <div *ngIf="tally.isFreeForm === 5">
                    <div class="row">
                      <div class="col-sm-6 col-md-2 text-center text-danger">
                        <div>
                          Very Dissatisfied
                        </div>
                        <div>
                          {{getRating(-2, tally.answer)}}
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 text-center text-warning">
                        <div>
                          Dissatisfied
                        </div>
                        <div>
                          {{getRating(-1, tally.answer)}}
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 text-center text-dark">
                        <div>
                          Neutral
                        </div>
                        <div>
                          {{getRating(0, tally.answer)}}
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 text-center text-primary">
                        <div>
                          Satisfied
                        </div>
                        <div>
                          {{getRating(1, tally.answer)}}
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 text-center text-success">
                        <div>
                          Very Satisfied
                        </div>
                        <div>
                          {{getRating(2, tally.answer)}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="overflow-y: scroll; max-height: 150px;" *ngIf="tally.isFreeForm === 0">
                    <div *ngFor="let answerString of tally.answer">
                      "{{answerString}}"
                      <hr>
                    </div>
                  </div>
                  <div *ngIf="tally.isFreeForm === 6">
                    <div>
                      Yes Count: {{getRating(2, tally.answer)}}
                    </div>
                    <div>
                      No Count: {{getRating(1, tally.answer)}}
                    </div>
                    <div>
                      Reason for not activating:
                    </div>
                    <div style="overflow-y: scroll; max-height: 150px;">
                      <div *ngFor="let reason of getYesNo(tally.answer)">
                        "{{reason}}"
                        <hr>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                  <div *ngIf="tally.isFreeForm === 2">
                    <div>
                      Yes Count: {{getRating(2, tally.answer)}}
                    </div>
                    <div>
                      No Count: {{getRating(1, tally.answer)}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </ng-template>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="tableSelect === 'overview'">
    <div class="row">
      <div *ngFor="let type5 of type5BarChartData" class="my-4 col-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{type5.question}}</h5>
            <div class="chart-wrapper">
              <canvas baseChart #type5ChartTag
                      [datasets]="type5.answer"
                      [labels]="type5.question"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="barChartType">
              </canvas>
            </div>
          </div>
        </div>
      </div>

      <div *ngFor="let type2 of type2BarChartData" class="my-4 col-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{type2.question}}</h5>
            <div class="chart-wrapper">
              <canvas baseChart #type2ChartTag
                      [datasets]="type2.answer"
                      [labels]="type2.question"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="barChartType">
              </canvas>
            </div>
          </div>
        </div>
      </div>

      <div *ngFor="let type6 of type6BarChartData" class="my-4 col-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{type6.question}}</h5>
            <div class="chart-wrapper">
              <canvas baseChart #type6ChartTag
                      [datasets]="type6.answer"
                      [labels]="type6.question"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="barChartType">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #overviewContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Survey results for:</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-4">
        <div class="">
          <h5>Question:</h5>
        </div>
        <div>
          <div class="">{{questionModal}}</div>
        </div>
      </div>
      <div class="col-4">
        <div class="">
          <h5>Answer:</h5>
        </div>
        <div>
          <div class="">{{answerModal}}</div>
        </div>
      </div>
      <div class="col-4">
        <div class="">
          <h5>Count:</h5>
        </div>
        <div>
          <div class="" *ngIf="surveyModalResults!=null">{{surveyModalResults.length}}</div>
        </div>
      </div>
    </div>
    <br>
    <div class="table-responsive" style="overflow-y: scroll; max-height: 400px;">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Mobile Number</th>
          <th scope="col">CSR</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let result of surveyModalResults">
          <td>
            {{ result.MobileNumber }}
          </td>
          <td>{{ result.CSRName }}</td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
  <div class="modal-footer">
<!--    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>-->
  </div>
</ng-template>
