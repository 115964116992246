import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FeedbackTableComponent } from './feedback-table/feedback-table.component';
import { SurveyComponent } from './survey/survey.component';
import { SubmitSuccessComponent } from './submit-success/submit-success.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SomethingWentWrongComponent } from './something-went-wrong/something-went-wrong.component';
import {NgxWebstorageModule} from 'ngx-webstorage';
import { FormDirective } from './form.directive';
import { ChartsModule } from 'ng2-charts';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    AppComponent,
    FeedbackTableComponent,
    SurveyComponent,
    SubmitSuccessComponent,
    SomethingWentWrongComponent,
    FormDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
    NgxWebstorageModule.forRoot(),
    ChartsModule,
    NgxGoogleAnalyticsModule.forRoot('G-1DE3LPJVZN'),
    NgxGoogleAnalyticsRouterModule,
    FontAwesomeModule
  ],
  providers: [FormDirective],
  bootstrap: [AppComponent]
})
export class AppModule { }
