<ng-template #successContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Thank you.</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Survey Submitted! Thank you for taking your time to answer our survey.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark btn-block btn-lg"
      (click)="modal.close('Save click')">Okay</button>
  </div>
</ng-template>

<form [formGroup]="surveyForm" (ngSubmit)="onSubmit()">
  <div class="side-padding">
    <div class=" mt-3">
      <div class="row">
        <div class="col-12 col-md-3 mb-1">
          <div>
            <div>
              <b class="h6 small font-weight-normal">Service Order</b>
            </div>
          </div>
          <div>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="orderNumber" id="orderNumber"
                aria-describedby="helpId" placeholder="">
              <div *ngIf="submitted && sf.orderNumber.errors">
                <div *ngIf="sf.orderNumber.errors.required" class="error">Service Order Number is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3 mb-1">
          <div>
            <div>
              <b class="h6 small font-weight-normal">Mobile Number</b>
            </div>
          </div>
          <div>
            <div class="form-group">
              <div class="">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">+63</div>
                  </div>
                  <input type="text" class="form-control" formControlName="mobileNumber" id="mobileNumber"
                    [minLength]="10" [maxLength]="10" aria-describedby="helpId" placeholder="">
                </div>
              </div>
              <div *ngIf="submitted && sf.mobileNumber.errors">
                <div *ngIf="sf.mobileNumber.errors.required" class="error">Mobile Number is required.</div>
                <div *ngIf="sf.mobileNumber.errors.minlength" class="error">Mobile Number should be 10 digits.</div>
                <div *ngIf="sf.mobileNumber.errors.maxlength" class="error">Mobile Number should only be 10 digits.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-0">
        <div class="col-6 col-md-3 mb-0">
          <div>
            <div>
              <b class="h6 small font-weight-normal">Visit Date</b>
            </div>
          </div>
          <div>
            <p class="font-weight-bolder">{{dateToday | date}}</p>
          </div>
        </div>
        <div class="col-6 col-md-3 mb-1">
          <div>
            <div>
              <b class="h6 small font-weight-normal">Branch</b>
            </div>
          </div>
          <div>
            <p class="font-weight-bolder">{{BranchName}}</p>
          </div>
        </div>
        <div class="col-6 col-md-3 mb-1" *ngIf="hideCSR">
          <div>
            <div>
              <b class="h6 small font-weight-normal">CSR Name</b>
            </div>
          </div>
          <div>
            <p class="font-weight-bolder mb-0 pb-0">{{CSRName}}</p>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-3">
    <div class="mt-1">
      <div class="row">
        <div class="col-lg">
          <p class="font-weight-bolder mb-2">Great Samsung Day!</p>
          <p class="h5 small mb-1">Your experience matters!</p>
          <p class="h5 small">Please help us how we can improve our services further.</p>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div class="row">
        <div class="col-md-12 col-sm-12 mb-3" *ngFor="let question of questions; index as i">
          <div class="card">
            <div class="card-body">
              <p>{{question.Question}}</p>
              <!-- <br> -->
              <div *ngIf="question.IsFreeForm === 5">
                <ul ngbNav [activeId]="0" #nav="ngbNav" class="nav-pills">
                  <li ngbNavItem (click)="clearCheckboxes(question.id)" class="">
                    <a class="happy" ngbNavLink >
                      <fa-icon style="font-size: 20pt;" [icon]="faSmile"></fa-icon>
                    </a>
                    <ng-template ngbNavContent>
                      <p class="mt-3 mb-2">Please select <strong>ALL</strong> that apply:</p>
                      <div class="form-check">
                        <input (click)="checkboxClick($event, question.id, 2, 'REPAIR SPEED')" class="form-check-input"
                          type="checkbox" id="REPAIR_SPEED_HAPPY">
                        <label class="form-check-label" for="REPAIR_SPEED_HAPPY">
                          Repair Speed
                        </label>
                      </div>
                      <div class="form-check">
                        <input (click)="checkboxClick($event, question.id, 2, 'REPAIR QUALITY')"
                          class="form-check-input" type="checkbox" [value]="{score:2, reason: 'REPAIR QUALITY'}"
                          id="REPAIR_QUALITY_HAPPY">
                        <label class="form-check-label" for="REPAIR_QUALITY_HAPPY">
                          Repair Quality
                        </label>
                      </div>
                      <div class="form-check">
                        <input (click)="checkboxClick($event, question.id, 1, 'REPAIR PRICE')" class="form-check-input"
                          type="checkbox" id="REPAIR_PRICE">
                        <label class="form-check-label" for="REPAIR_PRICE">
                          Repair Price
                        </label>
                      </div>
                      <div class="form-check">
                        <input (click)="checkboxClick($event, question.id, 1, 'SERVICE CENTER FACILITY')"
                          class="form-check-input" type="checkbox" id="SERVICE_CENTER_FACILITY_HAPPY">
                        <label class="form-check-label" for="SERVICE_CENTER_FACILITY_HAPPY">
                          Service Center Facility
                        </label>
                      </div>
                      <div class="form-check">
                        <input (click)="checkboxClick($event, question.id, 2, 'ASC STAFF ATTITUDE')"
                          class="form-check-input" type="checkbox" id="ASC_STAFF_ATTITUDE_HAPPY">
                        <label class="form-check-label" for="ASC_STAFF_ATTITUDE_HAPPY">
                          ASC Staff Attitude
                        </label>
                      </div>
                      <div class="form-check">
                        <input (click)="checkboxClick($event, question.id, 1, 'VALUE ADDED SERVICES')"
                          class="form-check-input" type="checkbox" id="VALUE_ADDED_SERVICES">
                        <label class="form-check-label" for="VALUE_ADDED_SERVICES">
                          Value Added Services
                        </label>
                      </div>
                    </ng-template>
                  </li>
                  <li ngbNavItem (click)="clearCheckboxes(question.id)">
                    <a ngbNavLink class="sad">
                      <fa-icon style="font-size: 20pt" [icon]="faFrown"></fa-icon>
                    </a>
                    <ng-template ngbNavContent>
                      <p class="mt-3 mb-2">Please select <strong>ALL</strong> that apply:</p>
                      <div class="form-check">
                        <input
                          (click)="checkboxClick($event, question.id, -2, 'LONG WAITING TIME ( MORE THAN 10 MINUTES)')"
                          class="form-check-input" type="checkbox" id="LONG_WAITING_TIME">
                        <label class="form-check-label" for="LONG_WAITING_TIME">
                          Long Waiting Time (More than 10 minutes)
                        </label>
                      </div>
                      <div class="form-check">
                        <input (click)="checkboxClick($event, question.id, -1, 'REPAIR QUALITY')"
                          class="form-check-input" type="checkbox" id="REPAIR_QUALITY_SAD_HAPPY">
                        <label class="form-check-label" for="REPAIR_QUALITY_SAD_HAPPY">
                          Repair Quality
                        </label>
                      </div>
                      <div class="form-check">
                        <input (click)="checkboxClick($event, question.id, -2, 'NO REPAIR FEEDBACK')"
                          class="form-check-input" type="checkbox" id="NO_REPAIR_FEEDBACK">
                        <label class="form-check-label" for="NO_REPAIR_FEEDBACK">
                          No Repair Feedback
                        </label>
                      </div>
                      <div class="form-check">
                        <input (click)="checkboxClick($event, question.id, -1, 'PARTS AVAILABILITY')"
                          class="form-check-input" type="checkbox" id="PARTS_AVAILABILITY">
                        <label class="form-check-label" for="PARTS_AVAILABILITY">
                          Parts Availability
                        </label>
                      </div>
                      <div class="form-check">
                        <input (click)="checkboxClick($event, question.id, 0, 'REPAIR PRICE')" class="form-check-input"
                          type="checkbox" value="0" id="REPAIR_PRICE_SAD">
                        <label class="form-check-label" for="REPAIR_PRICE_SAD">
                          Repair Price
                        </label>
                      </div>
                      <div class="form-check">
                        <input (click)="checkboxClick($event, question.id, -1, 'SERVICE CENTER FACILITY')"
                          class="form-check-input" type="checkbox" id="SERVICE_CENTER_FACILITY_SAD">
                        <label class="form-check-label" for="SERVICE_CENTER_FACILITY_SAD">
                          Service Center Facilty
                        </label>
                      </div>
                      <div class="form-check">
                        <input (click)="checkboxClick($event, question.id, -2, 'ASC STAFF ATTITUDE')"
                          class="form-check-input" type="checkbox" id="ASC_STAFF_ATTITUDE_SAD">
                        <label class="form-check-label" for="ASC_STAFF_ATTITUDE_SAD">
                          ASC Staff Attitude
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          (click)="checkboxClick($event, question.id, -1, 'LONG REQUEST APPROVAL (REPLACEMENT, DISCOUNT)')"
                          class="form-check-input" type="checkbox" id="LONG_REQUEST_APPROVAL">
                        <label class="form-check-label" for="LONG_REQUEST_APPROVAL">
                          Long Request Approval (Replacement, Discount)
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          (click)="checkboxClick($event, question.id, -1, 'CSR / TECHNICIAN KNOWLEDGE (PRODUCTS,SERVICES,REPAIR PROCEDURE)')"
                          class="form-check-input" type="checkbox" id="CSR_TECHNICIAN_KNOWLEDGE">
                        <label class="form-check-label" for="CSR_TECHNICIAN_KNOWLEDGE">
                          CSR / Technician Knowledge (Products, Services, Repair Procedure)
                        </label>
                      </div>
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
                <!--                <label ngbButtonLabel class="radio-custom btn-outline-success">-->
                <!--                  <input ngbButton type="radio" [value]="2"> Very Satisfied-->
                <!--                </label>-->
                <!--                <label ngbButtonLabel class="radio-custom btn-outline-primary">-->
                <!--                  <input ngbButton type="radio" [value]="1"> Satisfied-->
                <!--                </label>-->
                <!--                <label ngbButtonLabel class="radio-custom btn-outline-dark">-->
                <!--                  <input ngbButton type="radio" [value]="0"> Neutral-->
                <!--                </label>-->
                <!--                <label ngbButtonLabel class="radio-custom btn-outline-warning">-->
                <!--                  <input ngbButton type="radio" [value]="-1"> Dissatisfied-->
                <!--                </label>-->
                <!--                <label ngbButtonLabel class="radio-custom btn-outline-danger">-->
                <!--                  <input ngbButton type="radio" [value]="-2"> Very Dissatisfied-->
                <!--                </label>-->
              </div>


              <div *ngIf="question.IsFreeForm === 0">
                <textarea class="form-control" [ngModelOptions]="{standalone: true}" [maxLength]="500"
                  [(ngModel)]="answers[i].answer">
                </textarea>
                <div class="mt-2 text-right">
                  {{answers[i].answer.length}} / 500
                </div>
              </div>
              <div *ngIf="question.IsFreeForm === 2" class="btn-group btn-group-toggle btn-group-custom" ngbRadioGroup
                name="yesno" [ngModelOptions]="{standalone: true}" [(ngModel)]="answers[i].answer">
                <label ngbButtonLabel class="radio-custom btn-outline-success">
                  <input ngbButton type="radio" [value]="2"> Yes
                </label>
                <label ngbButtonLabel class="radio-custom btn-outline-primary">
                  <input ngbButton type="radio" [value]="1"> No
                </label>
              </div>
              <div *ngIf="question.IsFreeForm === 6">
                <div class="btn-group btn-group-toggle btn-group-custom" ngbRadioGroup name="yesnowhy"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="answers[i].yesNo">
                  <label ngbButtonLabel class="radio-custom btn-outline-success">
                    <input ngbButton type="radio" [value]="2"> Yes
                  </label>
                  <label ngbButtonLabel class="radio-custom btn-outline-primary">
                    <input ngbButton type="radio" [value]="1"> No
                  </label>
                </div>

                <div *ngIf="answers[i].yesNo === 1" class="mt-3">
                  <textarea class="form-control" [ngModelOptions]="{standalone: true}" [maxLength]="500"
                    [(ngModel)]="answers[i].answer" placeholder="Why?">
                  </textarea>
                  <div class="mt-2 text-right">
                    {{answers[i].answer.length}} / 500
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center mb-4 mt-0 pt-0">
          <p>
            <ngb-alert [dismissible]="false" type="danger" *ngIf="showAlert">
              Kindly answer the whole survey.
            </ngb-alert>
          </p>
          <button class="btn btn-dark btn-block btn-lg" type="button" (click)="onSubmit()"> Submit </button>
        </div>
      </div>
    </div>

  </div>
</form>
